import React from 'react';

export default function Upload( props ) {

	return (
		<>
			<label class="upload">
				<input onChange={(e) => {
					let fileName = e.target.files[0].name;
					let label = e.target.parentElement;
					let labelText = label.querySelector( 'span' );

					if(e.target.files[0].size > 8388608) {
						label.classList.remove( 'upload-added' );
						label.classList.add( 'upload-invalid' );
					} else {
						labelText.innerHTML = '';
						labelText.innerHTML = fileName;
						label.classList.remove( 'upload-invalid' );
						label.classList.add( 'upload-added' );
					}
				}
				} type="file" name={ props.placeholder } placeholder={ props.placeholder } /> 
				<span>{props.placeholder}</span>
			</label>
			<span className={ 'upload-error-message text:small' }>Your attachment is too large</span>
		</>
	);

}