import React from 'react';

export default function Email( props ) {

	return (
		<>
			<input onChange={ props.changeHandler } type="email" name={ props.placeholder } placeholder={ props.placeholder } /> 

			{ props.errors[ "email" ] && (
				<span className={ 'form-feedback text:header' }>{props.errors["email"]}</span>
			)}
		</>
	);

}