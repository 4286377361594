import React from 'react';
import { graphql } from 'gatsby';
import Text from '../../components/form/Text';
import Email from '../../components/form/Email';
import Dropdown from '../../components/form/Dropdown';
import Textarea from '../../components/form/Textarea';
import Upload from '../../components/form/Upload';
import '../../styles/components/form.scss';

class Form extends React.Component {

	constructor( props ) {
		super( props );

		this.state = {
			fields: {},
			errors: {}
		}
	}

	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

	    if (typeof fields["email"] !== "undefined") {
	      let lastAtPos = fields["email"].lastIndexOf("@");
	      let lastDotPos = fields["email"].lastIndexOf(".");

	      if (
	        !(
	          lastAtPos < lastDotPos &&
	          lastAtPos > 0 &&
	          fields["email"].indexOf("@@") == -1 &&
	          lastDotPos > 2 &&
	          fields["email"].length - lastDotPos > 2
	        )
	      ) {
	        formIsValid = false;
	        errors["email"] = "Please enter a valid email address.";
	      }
	    }

	    this.setState({ errors: errors });
	    return formIsValid;

	}

	handleChange(field, e) {
    	let fields = this.state.fields;
    	fields[field] = e.target.value;
    	this.setState({ fields });
    	this.handleValidation();
  	}

	handleSubmit = (e) => {
	  e.preventDefault();
	  // console.log(e.target);

	  	let feedback = document.querySelector( '.feedback' );
		let success = document.createElement( "p" );
		let message = document.createTextNode( "Thank you, form now submitted" );
		success.appendChild( message );

		let myForm = e.target;
		let formData = new FormData(myForm);

	    if (this.handleValidation()) {

			fetch("/", {
				body: new FormData(myForm),
				method: "POST",
				// headers: { "Content-Type": "application/x-www-form-urlencoded" },
				// body: new URLSearchParams(formData).toString(),
			})
			.then(() => {
				myForm.previousSibling.innerHTML = '';
				myForm.innerHTML = '';
				feedback.appendChild( success );
			})
			.catch((error) => alert(error));

	    }
	
	};

	render() {

		return (
			<div className="flex">
					<div 
						className="content"
						dangerouslySetInnerHTML={{ __html: this.props.standfirst }} 
					/>
					<div className="content">
						<div 
							className={ 'feedback' }
							dangerouslySetInnerHTML={{ __html: this.props.enquiries }}
						/>

						{ this.props.fields && (
							<form 
								id="form" 
								name={ this.props.formName } 
								data-netlify="true"
								className={ 'flex form' }
								method="post"
								enctype="multipart/form-data"
								onSubmit={ this.handleSubmit }
							>
								<input type="hidden" name="form-name" value={ this.props.formName } />

								{console.log(this.props)}
								{ this.props.fields && this.props.fields.formFields.map((field, i) => {
									if( field.type === 'text' ) {
										return (
											<Text key={i} placeholder={ field.placeholder } />
										)
									}
									
									if( field.type === 'email' ) {
										return (
											<Email errors={ this.state.errors } changeHandler={ this.handleChange.bind(this, "email") } key={i} placeholder={ field.placeholder } />
										)
									}
									
									if( field.type === 'textarea' ) {
										return (
											<Textarea key={i} placeholder={ field.placeholder } />
										)
									}

									if( field.type === 'upload' ) {
										return (
											<Upload key={i} placeholder={ field.placeholder } />
										)
									}

									if( field.type === 'dropdown' ) {
										return (
											<Dropdown 
												key={i}
												choices={ field.choices } 
												placeholder={ field.placeholder } 
											/>
										)
									}

								})}

								<input type="submit" value={this.props.buttonText } />

							</form>
						)}

					</div>
				</div>
			);

	}

}

export default Form;