import React from 'react';
import Img from 'gatsby-image';

export default function StudioImage( props ) {

	return (
		<>
		<figure>
			{ props.image  && (
				<Img fluid={ props.image } />
			)}
		</figure>

		{ props.enquiries && (
			<div 
				className={ 'content' }
				dangerouslySetInnerHTML={{ __html: props.enquiries }} 
  			/>
		)}
		</>
	);

}