import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Form from '../components/work-with-us/Form';
import StudioImage from '../components/work-with-us/StudioImage';
import '../styles/components/work-with-us.scss';
import Seo from '../components/Seo';

export default function WorkWithUs({data}) {
    return (
	    <>
			<Seo 
	    		bodyClasses="bg:bright" 
	    		siteTitle={data.wp.generalSettings.title}
				siteDescription={data.wp.generalSettings.description}
				title={data.wpPage.seo.title}
				metaDesc={data.wpPage.seo.metaDesc}
				focusKeywords={data.wpPage.seo.focuskw}
				opengraphTitle={data.wpPage.seo.opengraphTitle}
				opengraphDescription={data.wpPage.seo.opengraphDescription}
				opengraphImage={data.wpPage.seo.opengraphImage}
				opengraphImage={data.wpPage.seo.opengraphImage}
				twitterDescription={data.wpPage.seo.twitterDescription}
				twitterImage={data.wpPage.seo.twitterImage}
				twitterTitle={data.wpPage.seo.twitterTitle}
		    />

	      <Layout>
	      	<Header 
	      		title={ data.wpPage.title } 
	      		url={ data.wpPage.uri } 
	      		id={ data.wpPage.id }
	      	/>
		        <div className="work-with-us">
		        	<div className="flex">
		        		<Form 
		        			standfirst={ data.wpPage.content } 
		        			enquiries={ data.wpPage.workWithUs.enquiries } 
		        			fields={ data.wpPage.formBuilder } 
		        			buttonText={ "Submit" } 
		        			formName={ 'contact' }
		        		/>
		        		<StudioImage image={ data.wpPage.featuredImage.node.localFile.childImageSharp.fluid } enquiries={ data.wpPage.workWithUs.enquiries } />
		        	</div>
		        </div>
	        <Footer />
	      </Layout>
	    </>
    );
}

export const query = graphql`
	query WorkWithUs {
	  wpPage(id: {eq: "cG9zdDoxNw=="}, featuredImage: {}) {
	    id
	    title
	    content
	    uri
	    seo {
	      metaDesc
	      metaKeywords
	      metaRobotsNoindex
	      focuskw
	      opengraphAuthor
	      opengraphDescription
	      opengraphImage {
	        localFile {
	          childImageSharp {
	            fixed {
	              src
	            }
	          }
	        }
	      }
	      opengraphModifiedTime
	      opengraphPublishedTime
	      opengraphPublisher
	      opengraphSiteName
	      opengraphTitle
	      opengraphType
	      opengraphUrl
	      title
	      twitterDescription
	      twitterImage {
	        localFile {
	          childImageSharp {
	            fixed {
	              src
	            }
	          }
	        }
	      }
	      twitterTitle
	    }
	    workWithUs {
      		enquiries
    	}
	    formBuilder {
	      formFields {
	        placeholder
	        type
	        choices {
	          choice
	        }
	      }
	    }
	    featuredImage {
	      node {
	        sourceUrl
	        localFile {
	          childImageSharp {
	            fluid(maxWidth: 800) {
	            	...GatsbyImageSharpFluid
     	 			...GatsbyImageSharpFluidLimitPresentationSize
	            }
	          }
	        }
	      }
	    }
	  }
	wp {
	    generalSettings {
	      title
	      description
	    }
	  }
	}
`;
