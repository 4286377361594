import React, { useState } from 'react';

export default function Dropdown( props ) {

	const [isOpen, setIsOpen] = useState(false);
  	const [selectedOption, setSelectedOption] = useState(null);

  	const toggling = (e) => {
  		let activeDropdown = document.querySelectorAll( '.dropdown-active' );
  		e.target.parentElement.classList.toggle( 'dropdown-inactive' );
  		e.target.parentElement.classList.toggle( 'dropdown-active' );
		activeDropdown.forEach((active) => {
			active.classList.add( 'dropdown-inactive' );
			active.classList.remove( 'dropdown-active' );
		});
  	}
	const onOptionClicked = value => () => {
	    setSelectedOption(value);
	    let activeDropdown = document.querySelector( '.dropdown-active' );
	    activeDropdown.classList.add( 'dropdown-inactive' );
	    activeDropdown.classList.remove( 'dropdown-active' );
 	 };

	return (
		<>
			<div className={ isOpen ? "dropdown-container dropdown-active" : "dropdown-container dropdown-inactive" }>
			<input style={{ visibility: 'hidden', width: '0', height: '0', padding: '0', margin: '0', position: 'absolute' }} className={ 'dropdown-selected' } type="text" name={ props.placeholder } value={ selectedOption } />
				<div onClick={toggling} className={ selectedOption ? "dropdown-header colour:body" : "dropdown-header"}>

				{ selectedOption || props.placeholder }
				</div>

					<div className="dropdown-list-container">
					<ul className="dropdown-list">
					{ props.choices.map((choice, i) => (
						<li onClick={onOptionClicked(choice.choice)} key={i}>{ choice.choice }</li>
					)) }
					</ul>
				</div>
			</div>
		</>
	);

}
